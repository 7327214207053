import { Box, Button, Link, Stack, Text, useRadioGroup } from "@chakra-ui/react"
import * as Sentry from "@sentry/nextjs"
import { ethers } from "ethers"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import { useContractWrite, useWaitForTransaction } from "wagmi"
import { config } from "../utils/config"
import contractABI from "../utils/contractABI.json"
import { Radio } from "./Radio"

export const MintModalContent = ({ onMinted, isWhitelisted, proof }) => {
  const [err, setError] = useState(null)
  const [tx, setTx] = useState(null)
  const [mintNumber, setMintNumber] = useState(1)
  const [isLoading, setLoading] = useState(false)
  const options = ["1", "2", "3", "4", "5"]
  const basePrice = isWhitelisted ? config.friendPrice : config.price
  const {
    data,
    error,
    write: callContractMint,
  } = useContractWrite({
    address: config.contractAddress,
    abi: contractABI,
    functionName: isWhitelisted ? "friendMint" : "mint",
    value: ethers.utils.parseEther((parseInt(mintNumber) * basePrice).toString()),
  })
  const { data: txReceipt } = useWaitForTransaction({
    hash: tx,
  })

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "mintCount",
    defaultValue: "1",
    onChange: setMintNumber,
  })

  const group = getRootProps()

  const mintNFT = async () => {
    setLoading(true)
    setError(false)
    await callContractMint({
      args: isWhitelisted ? [parseInt(mintNumber), proof] : [parseInt(mintNumber)],
    })
  }

  useEffect(() => {
    if (txReceipt) {
      onMinted()
      setLoading(false)
    }
  }, [txReceipt])

  useEffect(() => {
    if (data) {
      setTx(data.hash)
    }
  }, [data])

  useEffect(() => {
    if (error) {
      setLoading(false)
      setError(true)
      Sentry.captureException(error)
    }
  }, [error])

  return (
    <Box position="relative">
      {isWhitelisted && (
        <Box
          bg="#1CE886"
          position="absolute"
          top="-45px"
          left="50%"
          transform="translateX(-50%)"
          fontWeight="semibold"
          borderRadius="20px"
          width="200px"
          border="1px solid #003F21"
          py={1}
          px={2}
        >
          🌈 You&apos;re on the friends list
        </Box>
      )}

      <Text mb={8}>
        How many <strong>Spectrums</strong> would you like to mint?
      </Text>

      <Stack direction="row" justifyContent="center" {...group}>
        {options.map((value) => {
          const radio = getRadioProps({ value })
          const isActive = mintNumber >= value
          return (
            <Radio
              key={value}
              isActive={isActive}
              zIndex={5 - value}
              image={`/selectors/${value}.svg`}
              {...radio}
            >
              {value}
            </Radio>
          )
        })}
      </Stack>
      <Button
        mt={8}
        mb={6}
        height="47px"
        borderRadius="xl"
        colorScheme="black"
        fontWeight="normal"
        onClick={mintNFT}
        isLoading={isLoading}
        w="full"
      >
        Mint Now{" "}
        <Box as="strong" ml={3}>
          {(parseFloat(mintNumber) * basePrice).toFixed(3)} ETH
        </Box>
        {isWhitelisted && (
          <Box pl={3} as="span" textDecoration="line-through">
            {(parseFloat(mintNumber) * config.price).toFixed(3)} ETH
          </Box>
        )}
      </Button>
      {isWhitelisted && !isLoading && (
        <Text mb={4} color="#00A455">
          Friends list discount applied
        </Text>
      )}
      {tx && (
        <Box mb={4}>
          <Text color="blackAlpha.700">Waiting for blockchain confirmation.</Text>
          <Text>
            <Link isExternal href={`${process.env.NEXT_PUBLIC_ETHERSCAN_URL}tx/${tx}`}>
              See transaction
            </Link>{" "}
            on Etherscan
          </Text>
        </Box>
      )}
      {err && (
        <Box mb={4}>
          <Text>Something went wrong</Text>
        </Box>
      )}
      {!isLoading && (
        <Text color="blackAlpha.700">
          Each of your spectrums will be random on mint. We use ERC-721A to keep gas as
          low as possible.
        </Text>
      )}
    </Box>
  )
}

MintModalContent.propTypes = {
  onMinted: PropTypes.func.isRequired,
  isWhitelisted: PropTypes.bool,
  proof: PropTypes.array,
}
