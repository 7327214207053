import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  Button,
  useDisclosure,
  Box,
  Spinner,
  Text,
  Flex,
} from "@chakra-ui/react"
import { useCallback, useEffect, useState } from "react"
import { useAccount } from "wagmi"
import axios from "axios"
import * as Sentry from "@sentry/nextjs"
import { ConnectModalContent } from "./ConnectModalContent"
import { MintModalContent } from "./MintModalContent"
import { MintedModalContent } from "./MintedModalContent"
import { ConnectKitButton } from "connectkit"

export const Modal = () => {
  const [isMinted, setMinted] = useState(false)
  const [isWhitelisted, setWhitelisted] = useState(undefined)
  const [proof, setProof] = useState(null)
  const { address, isConnected } = useAccount()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const verifyForWhitelist = useCallback(() => {
    axios
      .post("/api/verify", {
        address,
      })
      .then(function (response) {
        const { data } = response

        if (data.whitelisted) {
          setProof(data.proof)
        }

        setWhitelisted(data.whitelisted)
      })
      .catch(function (error) {
        Sentry.captureException(error)
      })
  }, [address])

  useEffect(() => {
    if (address && !isWhitelisted) {
      verifyForWhitelist()
    }
  }, [isWhitelisted, address, verifyForWhitelist])

  return (
    <>
      <ConnectKitButton.Custom>
        {({ isConnected, isConnecting, show, hide, address, ensName, chain }) => {
          return (
            <Button
              mt={4}
              height="32px"
              px={4}
              lineHeight={1}
              fontSize="sm"
              borderRadius="lg"
              colorScheme="white"
              color="black"
              onClick={isConnected ? onOpen : show}
            >
              {isConnected ? "Mint a Spectrum" : "Connect Wallet"}
            </Button>
          )
        }}
      </ConnectKitButton.Custom>

      <ChakraModal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          borderRadius="2xl"
          py={10}
          px={8}
          textAlign="center"
          alignSelf="center"
          my={0}
          color="black.500"
        >
          {isMinted ? (
            <MintedModalContent />
          ) : (
            <>
              {address ? (
                <>
                  {isWhitelisted === undefined ? (
                    <Box py={8}>
                      <Spinner size="sm" />
                      <Text mt={4}>Verifiying you&apos;re a friend</Text>
                    </Box>
                  ) : (
                    <MintModalContent
                      isWhitelisted={isWhitelisted}
                      proof={proof}
                      onMinted={() => setMinted(true)}
                    />
                  )}
                </>
              ) : (
                <ConnectModalContent />
              )}
            </>
          )}
        </ModalContent>
      </ChakraModal>
    </>
  )
}
